<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <v-img style="max-width: 180px" src="/img/assignments/ladder.png" />
      </p>

      <p class="mb-2">
        At the <b>top</b> of the ladder are the people who are the best off, those who have the most
        money, most education and best jobs.
      </p>
      <p class="mb-2">
        At the <b>bottom</b> are the people who are the worst off, those who have the least money,
        least education, worst jobs, or no job.
      </p>
      <p class="mb-5">
        Now think about your immediate family. Please tell us where you think your immediate family
        would be on this ladder.
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-3">
        <v-radio v-for="option in options" :key="option.value" :value="option.value" class="my-3">
          <template #label>
            <stemble-latex :content="option.value" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'UPEILadder',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {value: '10'},
        {value: '9'},
        {value: '8'},
        {value: '7'},
        {value: '6'},
        {value: '5'},
        {value: '4'},
        {value: '3'},
        {value: '2'},
        {value: '1'},
      ],
    };
  },
};
</script>
<style scoped></style>
